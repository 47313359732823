import { RideFormValues } from '@prague-old-car/api-client-forms'
import { Decorator, FormApi } from 'final-form'

const requiredCarCount = (persons: number, perCar: number) => ((persons - 1) / perCar >> 0) + 1

export const createPersonsDecorator = (): Decorator<RideFormValues> =>
    (form: FormApi<RideFormValues>) => {
        let previousValues: RideFormValues = {} as any
        const unsubscribe = form.subscribe(({ values }) => {
            if (values.persons !== previousValues.persons) {
                const carCount = requiredCarCount(values.persons, 5)
                if (carCount > values.carCount) {
                    form.batch(() => {
                        form.change("carCount", carCount)
                    })
                }
            }
            previousValues = values
        }, {
            values: true
        })
        return unsubscribe
    }

export const isCarCountValid = (values: RideFormValues) => {
    const requiredCars = requiredCarCount(values.persons || 1, 5)
    if ((values.carCount || 1) < requiredCars) return false
    return true
}