import React, { useCallback, useState } from 'react';

import Modal from './Components/Modal'


import { MdArrowBack, MdPhone } from 'react-icons/md'

import OrderDetail from './Containers/OrderDetail';
import { AppState } from '../AppState';
import ClientForm from './Containers/ClientForm';
import { API, Ride } from '@prague-old-car/api-client';
import { APIProvider } from '@prague-old-car/api-client-hooks';

const App: React.FC<{ state: AppState }> = ({ state: initialState }) => {
    const [showContact, setShowContact] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState(initialState)

    const createOrder = useCallback((order: Ride) => {
        setIsLoading(true)
        new API.Orders().create(order)
            .then((order) => {
                try {
                    //@ts-ignore
                    gtag_report_conversion(order.getPriceOfRide().getInclVAT().add(order.getPriceOfProducts().getTotalInclVAT()) / 100);
                }
                finally {
                    setState({ ...state, order })
                    history.pushState(null, "Order #" + order.getID(), "order/" + order.userToken); // Add new the browser state
                    window.dispatchEvent(new Event('popstate'));
                }
            })
            .finally(() => setIsLoading(false))
    }, [])

    const error = null

    return (
        <div>
            <nav className="navbar fixed-top">
                <div className="container-fluid p-2 px-lg-5">
                    <a href={"http://pragueoldcar.com/"}><div className="row align-items-center"><MdArrowBack className="mr-2" size={20} /> Back to our website</div></a>
                    <a href="#" onClick={(e) => { e.preventDefault(); setShowContact(true) }}><div className="row align-items-center">Contact us <MdPhone className="ml-2" size={20} /></div></a>
                </div>
            </nav>

            {(state.order) ?
                <APIProvider options={{ hubUrl: "", token: "" }}>
                    <OrderDetail order={state.order} routes={state.routes} products={state.products} />
                </APIProvider>
                :
                <ClientForm
                    routes={state.routes}
                    products={state.products}
                    carTypes={state.carTypes}
                    createOrder={(order) => createOrder(order)} />
            }


            {error ?
                <Modal name="error" title="An error has occured" content={error} />
                : null}

            {showContact ?
                <Modal name="contact" title="Contact" onClose={(e) => setShowContact(false)} content={
                    <div className="text-center">
                        <h6 className="font-primary font-weight-bold text-dark-gray">{"Prague old car s.r.o."}</h6>
                        <p className="small">
                            <b>Email: {"info@pragueoldcar.com"}</b><br />
                            <b>Phone: +420 702 000 044</b>
                        </p>
                    </div>
                } /> : null
            }

            <div className={(isLoading) ? "loading-overlay active" : "loading-overlay"}></div>

        </div>
    )
}

export default App