import React, { useEffect, useMemo } from 'react';
import { MdAccessTime, MdPeople } from 'react-icons/md';

import { Ride, RideAssignment, RideSource, Route } from '@prague-old-car/api-client';
import { FormFieldProps } from './FormField';
import Params from '../../Params';
import { useForm } from 'react-final-form';

type SelectRouteProps = {
    routes?: Route[]
    showTitle?: boolean
    showLimo?: boolean
    showDescription?: boolean
}

enum RouteCategory {
    PRAGUE = "Prague",
    CASTLES = "Towns & Castles"
}

const SelectRoute: React.FC<FormFieldProps<number> & SelectRouteProps> = ({ input, routes, showTitle, showDescription }) => {
    const routesByCategory = useMemo(() => {
        const routesByCategory: { [key in keyof typeof RouteCategory]: Route[] } = {
            PRAGUE: [],
            CASTLES: []
        }

        routes?.filter(route => route.hasFixedPrice()).forEach((route) => {
            if (route.hasCategoryID(Params.CatPOCPrague))
                routesByCategory.PRAGUE.push(route)

            if (route.hasCategoryID(Params.CatPOCCastles))
                routesByCategory.CASTLES.push(route)
        });

        return routesByCategory
    }, [routes])

    const setActiveRoute = (id: number, ev?: React.MouseEvent) => {
        if (ev !== undefined) ev.preventDefault()
        input.onChange(id);
    }

    useEffect(() => {
        setActiveRoute(input.value || routesByCategory.PRAGUE[0]?.getID());
    }, [])

    if (!routes) return null

    const active = routes.find(route => route.getID() === input.value);

    return (
        <div>
            <section className="container input-container tour-select-container">
                {showTitle === true && <div className="h4">Choose a tour</div>}
                <div className="tour-list">

                    {Object.keys(routesByCategory).map((cat) => {
                        const category = RouteCategory[cat as keyof typeof RouteCategory]
                        const values = routesByCategory[cat as keyof typeof RouteCategory] as Route[]
                        if (!values) return

                        return (
                            <div style={(category == RouteCategory.PRAGUE) && { marginBottom: '25px' } || {}} key={category}>
                                <div className="label">{category}:</div>
                                <div className={"row no-gutters flex-wrap align-items-start" + (category == RouteCategory.PRAGUE && " mr-md-5" || "")}>
                                    {values.map((route) =>
                                        <button type="button" onClick={(ev) => setActiveRoute(route.getID(), ev)} key={route.getID()} className={(route.getID() === active?.getID()) ? "tour tour--active" : "tour"}>
                                            <div className="tour__icon" style={{ backgroundColor: route.color }}>
                                                {route.shortcut}
                                            </div>
                                            <div className="tour__title">{route.name.toLocaleUpperCase()}</div>
                                            {(route.hasFixedPrice()) &&
                                                <div className="tour__info">{route.getDuration().asMinutes()} min</div>
                                            }
                                        </button>
                                    )}
                                </div>
                            </div>
                        )
                    })}

                </div>
            </section>

            {(showDescription != false && active !== undefined && active.getID() !== null) &&
                <div className="w-100 bg-darker">
                    <section className="container text-black">
                        <div className="row no-gutters mb-4 mt-2 justify-content-between align-items-center">
                            <h3 className="font-primary mr-5 mb-3 mt-3">{active.name.toLocaleUpperCase()}</h3>

                            <div className="row no-gutters justify-content-between align-items-end">
                                <div className="tour-info-icon text-center mr-4">
                                    <MdAccessTime size={30} />
                                    <div className="mt-1">{active.getDuration().asMinutes().toString()} min</div>
                                </div>
                                <div className="tour-info-icon text-center">
                                    <MdPeople size={35} />
                                    <div className="mt-0">{5} people</div>
                                </div>
                            </div>

                        </div>

                        <div dangerouslySetInnerHTML={{ __html: active.description }} className="tour-info-text text-dark-gray">
                        </div>

                        <div className="row no-gutters mt-4 mb-3 justify-content-between align-items-center">
                            <div className="text-center font-secondary mr-5 text-dark-gray">
                                Price per car:
                            </div>

                            <div className="h4 font-weight-normal mb-0">
                                {active.getPrice(undefined, RideSource.OnlineBooking).getInclVAT().format()} / {active.getPrice(undefined, RideSource.OnlineBooking).convert(Params.EURCZK).getInclVAT().format()}
                            </div>
                        </div>

                    </section>
                </div>
            }

        </div>
    );
}

export default SelectRoute;