import React, { useEffect, useMemo } from "react";

import SelectRoute from "../Components/SelectRoute";
import FormField from "../Components/FormField";
import FormAutocompleteField from "../Components/FromAutocompleteField";

import {
    CarType,
    Product,
    Ride,
    RideAssignment,
    RideSource,
    Route,
} from "@prague-old-car/api-client";
import {
    createCalculateDecorator,
    mustBeEmail,
    required,
    RideForm,
    RideFormValues,
} from "@prague-old-car/api-client-forms";
import Params from "../../Params";
import { Quantity } from "@marekl/prices";
import {
    createPersonsDecorator,
    isCarCountValid,
} from "../Libs/personsDecorator";
import { Decorator } from "final-form";

interface ClientFormProps {
    routes: Route[];
    products: Product[];
    carTypes: CarType[];
    createOrder: (order: Ride) => void;
}

const ClientForm: React.FC<ClientFormProps> = ({
    routes,
    products,
    carTypes,
    createOrder,
}) => {
    const query = new URLSearchParams(location.search);
    const initRoute =
        routes.find(
            (route) => route.getID() === parseInt(query.get("tour") || "", 10)
        ) || routes[0];

    if (!initRoute) return null;

    const initOrder = new Ride({
        assignments: [
            new RideAssignment({
                carTypeId: Params.CarTypeVeteran,
                price: initRoute.getPrice(
                    Params.CarTypeVeteran,
                    RideSource.OnlineBooking
                ),
            }),
        ],
        carCount: 1,
        persons: 1,
    }).setRoute(initRoute);

    const personsDecorator = useMemo(() => createPersonsDecorator(), []);

    const routeCalculation = createCalculateDecorator(
        {
            field: "routeId",
            updates: {
                assignments: (_, v: object | undefined) => {
                    if (!v) return;
                    const values = v as RideFormValues;
                    const carCount = values.carCount;
                    if (!carCount || carCount <= 0) return values.assignments;

                    let routeId = values.routeId;

                    const prevAssignment =
                        values.assignments?.[0] || initOrder.assignments[0];

                    const assignments = Array.from(Array(carCount).keys()).map(
                        (i) =>
                            new RideAssignment({
                                carTypeId: prevAssignment.carTypeId,
                                price: routes
                                    .find((route) => route.getID() === routeId)
                                    ?.getPrice(
                                        Params.CarTypeVeteran,
                                        RideSource.OnlineBooking
                                    ),
                            })
                    );

                    return assignments;
                },
            },
        },
        {
            field: "carCount",
            updates: {
                // Multiply assignments for new ride
                assignments: (_, v: Object | undefined) => {
                    if (!v) return initOrder.assignments;
                    const values = v as RideFormValues;
                    const carCount = values.carCount;
                    if (values.id) return values.assignments;
                    if (!carCount || carCount <= 0) return values.assignments;

                    const prevAssignment =
                        values.assignments?.[0] || initOrder.assignments[0];

                    const assignments = Array.from(Array(carCount).keys()).map(
                        (i) =>
                            new RideAssignment({
                                carTypeId: prevAssignment.carTypeId,
                                price: prevAssignment.price,
                            })
                    );

                    return assignments;
                },
            },
        }
    ) as Decorator<RideFormValues>;

    useEffect(() => {
        $(window).trigger("resize");
    }, []);

    const bouquet = products.find(
        (product) => product.slug === "roses-veterans"
    );
    const sekt = products.find(
        (product) => product.slug === "prosecco-veterans"
    );
    const carType = carTypes.find(
        (carType) => carType.getID() === Params.CarTypeVeteran
    );

    return (
        <div>
            <div className="container-fluid p-0 header-img"></div>

            <header className="container-fluid header">
                <h1 className="text-center">New reservation</h1>
            </header>

            <RideForm
                products={products}
                routes={routes}
                agencies={[]}
                initialData={initOrder}
                decorators={[personsDecorator, routeCalculation]}
                agreement={true}
                validators={{
                    email: [
                        [required, "Vyplňte platnou emailovou adresu"],
                        [mustBeEmail, "Vyplňte platnou emailovou adresu"],
                    ],
                    phone: [
                        [
                            (_, values) => values.phone.number.length > 0,
                            "Vyplňte platné telefonní číslo s předvolbou",
                        ],
                    ],
                    time: [
                        [
                            (_, values) => {
                                if (!values.time.includes(":")) return true;
                                const [hours, minutes] = values.time
                                    .split(":")
                                    .map((part) => parseInt(part));
                                if (
                                    Number.isNaN(hours) ||
                                    Number.isNaN(minutes)
                                )
                                    return true;
                                return (
                                    hours >= 9 &&
                                    (hours < 18 ||
                                        (hours === 18 && minutes === 0))
                                );
                            },
                            "Začátek jízdy musí být mezi 9:00 a 18:00",
                        ],
                    ],
                }}
                postValidators={[
                    [
                        isCarCountValid,
                        { carCount: `Max. ${5} persons per car` },
                    ],
                ]}
                onSubmit={(order) => createOrder(order)}
            >
                <RideForm.Route
                    component={SelectRoute}
                    showTitle={true}
                    showDescription={true}
                    routes={routes}
                />

                <section className="container user-inputs">
                    <div className="row align-items-start">
                        <RideForm.Date
                            title="Date"
                            placeholder="Pick a date..."
                            className="col"
                            inputClass="date-picker"
                            component={FormField}
                        />
                        <RideForm.Time
                            title="Time"
                            className="col"
                            inputClass="time-picker"
                            placeholder="Pick a time..."
                            component={FormField}
                        />
                    </div>

                    <div className="row align-items-start">
                        <RideForm.Persons
                            format={(v) => (v > 0 ? v : "")}
                            parse={(v) => parseInt(v, 10)}
                            formatOnBlur
                            title="Number of people"
                            className="col"
                            component={FormField}
                        />
                        <RideForm.CarCount
                            format={(v) => (v > 0 ? v : "")}
                            parse={(v) => parseInt(v, 10)}
                            formatOnBlur
                            title="Number of cars"
                            className="col"
                            autoComplete="new-password"
                            component={FormField}
                        />
                    </div>

                    <div className="row">
                        <RideForm.PickUp
                            className="col"
                            title="Pickup address or name of your hotel"
                            autoComplete="new-password"
                            component={FormAutocompleteField}
                        />
                    </div>

                    <div className="row">
                        <RideForm.Note
                            className="col"
                            title="Message"
                            rows={3}
                            placeholder="Let us know about your additional wishes and requirements..."
                            component={FormField}
                        />
                    </div>

                    <div className="row">
                        <fieldset className="form-group col language-select">
                            <legend className="col-form-label pt-0">
                                Language
                            </legend>
                            <RideForm.Nationality
                                className="custom-control-inline"
                                type="radio"
                                value="en"
                                title="English"
                                component={FormField}
                            />
                            <RideForm.Nationality
                                className="custom-control-inline"
                                type="radio"
                                value="cz"
                                title="Czech"
                                component={FormField}
                            />
                        </fieldset>
                    </div>

                    <div className="row">
                        <fieldset className="form-group col additional-service">
                            <legend className="col-form-label pt-0">
                                Additional service
                            </legend>
                            <RideForm.Consumer>
                                {(ride) => (
                                    <>
                                        {sekt && (
                                            <RideForm.ProductList
                                                format={(list) =>
                                                    list?.entries.some(
                                                        (entry) =>
                                                            entry.productId ===
                                                                sekt.getID() &&
                                                            entry.quantity
                                                                .value === 1000
                                                    )
                                                }
                                                parse={(addSekt) =>
                                                    ride
                                                        .getProducts()
                                                        .setQuantity(
                                                            sekt,
                                                            addSekt
                                                                ? Quantity.of(1)
                                                                : Quantity.of(0)
                                                        )
                                                }
                                                type="checkbox"
                                                id="input-sekt"
                                                className="custom-control-inline custom-control-multiline mb-3"
                                                title={
                                                    <div className="d-flex flex-row">
                                                        <div
                                                            className="mr-2 ml-1"
                                                            style={{
                                                                fontSize:
                                                                    "1.5em",
                                                            }}
                                                        >
                                                            <svg className="icon icon-sekt">
                                                                <use xlinkHref="#icon-sekt"></use>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            Prosecco (0,7 l)
                                                            <br />
                                                            <span className="extra-small">
                                                                {sekt.price
                                                                    .getInclVAT()
                                                                    .format()}{" "}
                                                                /{" "}
                                                                {sekt.price
                                                                    .convert(
                                                                        Params.EURCZK
                                                                    )
                                                                    .getInclVAT()
                                                                    .format()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                component={FormField}
                                            />
                                        )}
                                        {bouquet && (
                                            <RideForm.ProductList
                                                format={(list) =>
                                                    list?.entries.some(
                                                        (entry) =>
                                                            entry.productId ===
                                                                bouquet.getID() &&
                                                            entry.quantity
                                                                .value === 1000
                                                    )
                                                }
                                                parse={(addBouqet) =>
                                                    ride
                                                        .getProducts()
                                                        .setQuantity(
                                                            bouquet,
                                                            addBouqet
                                                                ? Quantity.of(1)
                                                                : Quantity.of(0)
                                                        )
                                                }
                                                type="checkbox"
                                                id="input-bouquet"
                                                className="custom-control-inline custom-control-multiline"
                                                title={
                                                    <div className="d-flex flex-row">
                                                        <div
                                                            className="mr-2 ml-1"
                                                            style={{
                                                                fontSize:
                                                                    "1.5em",
                                                            }}
                                                        >
                                                            <svg
                                                                className="icon icon-bouquet"
                                                                style={{
                                                                    marginBottom:
                                                                        "4px",
                                                                }}
                                                            >
                                                                <use xlinkHref="#icon-bouquet"></use>
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            Roses
                                                            <br />
                                                            <span className="extra-small">
                                                                {bouquet.price
                                                                    .getInclVAT()
                                                                    .format()}{" "}
                                                                /{" "}
                                                                {bouquet.price
                                                                    .convert(
                                                                        Params.EURCZK
                                                                    )
                                                                    .getInclVAT()
                                                                    .format()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                component={FormField}
                                            />
                                        )}
                                    </>
                                )}
                            </RideForm.Consumer>
                        </fieldset>
                    </div>
                </section>

                <div className="w-100 bg-darker">
                    <section className="container">
                        <div className="row no-gutters mb-2 justify-content-between align-items-center">
                            <div className="text-center font-secondary mr-5">
                                Total price:
                            </div>
                            <div className="h4 font-weight-normal mb-0 text-black">
                                <RideForm.Consumer>
                                    {(order) => {
                                        const total = order
                                            .getPriceOfRide()
                                            .getInclVAT()
                                            .add(
                                                order
                                                    .getPriceOfProducts()
                                                    .getTotalInclVAT()
                                            );

                                        return `${total.format()} / ${total
                                            .convert(Params.EURCZK)
                                            .format()}`;
                                    }}
                                </RideForm.Consumer>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4 mt-md-0">
                            <div>
                                <p className="text-primary mb-0">
                                    Payment in advance <b>in cash</b> to the
                                    driver (we accept euros and czech crowns)
                                </p>
                            </div>
                            <div>
                                <p className="text-right text-black text-nowrap mb-0">
                                    <span className="mr-3">Cash only</span>
                                    <svg className="icon icon-cash-only h1">
                                        <use xlinkHref="#icon-cash-only"></use>
                                    </svg>
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <section className="container user-inputs">
                    <h5 className="font-primary text-dark-gray font-weight-normal mt-2 mb-5">
                        Please provide us with some details about you so we can
                        contact you
                    </h5>

                    <div className="row">
                        <RideForm.Name
                            className="col"
                            title="Your name"
                            component={FormField}
                        />
                    </div>

                    <div className="row">
                        <RideForm.Email
                            className="col"
                            title="Email address"
                            component={FormField}
                        />
                    </div>

                    <div className="row">
                        <RideForm.Phone
                            className="col"
                            title="Phone number"
                            autoComplete="tel"
                            component={FormField}
                        />
                    </div>

                    <div className="row mt-4 mb-4">
                        <div className="form-group col">
                            <RideForm.Agreement
                                title={
                                    <div>
                                        I have read and agree to the{" "}
                                        <a
                                            target="blank"
                                            href="https://cdn.pragueoldcar.com/prague-old-car/documents/terms-and-conditions_cs.pdf"
                                        >
                                            <u>Terms and Conditions</u>
                                        </a>{" "}
                                        and{" "}
                                        <a
                                            target="blank"
                                            href="https://cdn.pragueoldcar.com/prague-old-car/documents/gdpr_cs.pdf"
                                        >
                                            <u>Privacy Policy</u>
                                        </a>
                                    </div>
                                }
                                component={FormField}
                            />
                        </div>
                    </div>
                </section>

                <div className="w-100 bg-darker">
                    <section className="container text-dark-gray text-center">
                        <h5 className="font-primary text-dark-gray font-weight-normal mt-2 mb-1">
                            All is done! Please enjoy your ride.
                        </h5>
                        <div className="small font-primary">
                            We will email you the details of your tour
                            within&nbsp;an&nbsp;hour.
                        </div>
                        <button
                            type="submit"
                            className="btn btn-block btn-primary mt-4 mb-4"
                        >
                            Book your tour!
                        </button>
                        {/* <OrderForm.Submit
                                component="button"
                                className="btn btn-block btn-primary mt-4 mb-4">
                                Book your tour!
                            </OrderForm.Submit> */}
                        <h5 className="font-primary text-dark-gray font-weight-normal mt-2 mb-1">
                            Thank you for choosing our&nbsp;company!
                        </h5>
                    </section>
                </div>
            </RideForm>

            <section className="container text-center">
                <h6 className="font-primary font-weight-bold text-dark-gray">
                    Prague old car s.r.o.
                </h6>

                <p className="small">
                    <b>Email: info@pragueoldcar.com </b>
                    <br />
                    <b>Phone: +420 702 000 044</b>
                </p>

                <p className="small mb-4">
                    Rybná 716/24
                    <br />
                    Staré Město
                    <br />
                    110 00 Praha 1<br />
                    IČO: 01503251
                    <br />
                    207563 C, Městský soud v Praze
                </p>

                <p className="small">&copy; Prague old car s.r.o.</p>
                <p className="small">
                    Icons made by{" "}
                    <a
                        href="https://www.flaticon.com/authors/those-icons"
                        title="Those Icons"
                    >
                        Those Icons
                    </a>{" "}
                    and{" "}
                    <a href="http://www.freepik.com" title="Freepik">
                        Freepik
                    </a>{" "}
                    from{" "}
                    <a href="https://www.flaticon.com/" title="Flaticon">
                        www.flaticon.com
                    </a>{" "}
                    is licensed by{" "}
                    <a
                        href="http://creativecommons.org/licenses/by/3.0/"
                        title="Creative Commons BY 3.0"
                        target="_blank"
                    >
                        CC 3.0 BY
                    </a>
                </p>
            </section>
        </div>
    );
};

export default ClientForm;
