import React, { useCallback, useMemo } from "react"
import { Ride } from "@prague-old-car/api-client"
import GoogleMapReact from "google-map-react"
import { isDefined } from "@prague-old-car/api-client-forms"


const CarMarker: React.FC<{ lat: number, lng: number }> = ({ lat, lng }) => {
    return <div style={{
        fontSize: "40px",
        width: "40px",
        height: "40px",
        position: "absolute",
        left: "-20px",
        top: "-20px",
    }}>
        <svg className="icon icon-car"><use xlinkHref="#icon-car"></use></svg>
    </div>
}


const PinMarker: React.FC<{ lat: number, lng: number }> = ({ lat, lng }) => {
    return <div style={{
        fontSize: "40px",
        width: "40px",
        height: "40px",
        position: "absolute",
        left: "-20px",
        top: "-40px",
    }}>
        <svg className="icon icon-marker"><use xlinkHref="#icon-marker"></use></svg>
    </div>
}

const CarMap: React.FC<{
    ride: Ride
}> = ({ ride }) => {

    const location = useMemo(() => {
        const teamLeader = ride.assignments.length == 1 ? ride.assignments[0] : ride.assignments.find(assignment => assignment.teamLeader)
        if (teamLeader?.meta?.lat && teamLeader?.meta?.lon)
            return { lat: teamLeader?.meta?.lat, lon: teamLeader?.meta?.lon }
        return null
    }, [ride])

    const onGoogleAPILoaded = useCallback(({ map }) => {
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ 'address': ride.pickUp }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {

                if (location) {
                    const bounds = new google.maps.LatLngBounds()
                    bounds.extend(results[0].geometry.location)
                    bounds.extend(new google.maps.LatLng(location.lat, location.lon))
                    map.fitBounds(bounds)
                }

                var infowindow = new google.maps.InfoWindow(
                    {
                        content: '<b>' + ride.pickUp + '</b>',
                    });

                var marker = new google.maps.Marker({
                    position: results[0].geometry.location,
                    map: map,
                    title: ride.pickUp
                });
                google.maps.event.addListener(marker, 'click', function () {
                    infowindow.open(map, marker);
                });
            } else {
                alert("Geocode was not successful for the following reason: " + status);
            }
        });
    }, [ride.pickUp, location])

    return <div style={{ margin: '0px auto', width: '100%', height: '600px' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDhiUBuA3Pyrpp0vG0iFq4jVTs--vRYHr8' }}
            defaultCenter={{ lat: 50.0784372, lng: 14.4237719 }}
            defaultZoom={13}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={onGoogleAPILoaded}
        >
            {location ?
                <CarMarker lat={location.lat} lng={location.lon} /> : null}
        </GoogleMapReact>
    </div>
}

export default CarMap