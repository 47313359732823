import moment from "moment-timezone";

(function ($, Modernizr, window) {
    /*globals moment*/
    $.fn.bootstrapDatepickerGetMoment = (val) => {
        var valMoment;

        var nativeDateFormat = /^\d{4}-\d{2}-\d{2}$/;
        var datepickerDateFormat = /^\d{2}\.\d{2}\.\d{4}$/;

        if (nativeDateFormat.test(val)) {
            valMoment = moment(val, "YYYY-MM-DD");
        } else if (datepickerDateFormat.test(val)) {
            valMoment = moment(val, "DD.MM.YYYY");
        }

        return valMoment;
    };

    $.fn.bootstrapDatepickerGetPragueMoment = (val) => {
        var valMoment;

        var nativeDateFormat = /^\d{4}-\d{2}-\d{2}$/;
        var datepickerDateFormat = /^\d{2}\.\d{2}\.\d{4}$/;

        if (nativeDateFormat.test(val)) {
            valMoment = moment.tz(val, "YYYY-MM-DD", "Europe/Prague");
        } else if (datepickerDateFormat.test(val)) {
            valMoment = moment.tz(val, "DD.MM.YYYY", "Europe/Prague");
        }

        return valMoment;
    };

    /*globals moment*/
    $.fn.bootstrapDatepickerMobile = (ev) => {
        const options = {
            format: "dd.mm.yyyy",
            autoclose: true,
            startDate: moment().toISOString(),
        };

        var $inputs = $("input.date-picker");
        var isMobile = $(window).width() <= 480 || Modernizr.touch;

        $inputs.each(function () {
            var $input = $(this);
            var val = $input.val();

            var valMoment = $().bootstrapDatepickerGetMoment(val);

            var isMoment = moment.isMoment(valMoment);

            if (isMobile && Modernizr.inputtypes.date) {
                if (isMoment) val = valMoment.format("YYYY-MM-DD");
                $input.datepicker("destroy");
                $input.val(val);
                $input.trigger("change");
                $input.attr("type", "date");
            } else {
                if (isMoment) val = valMoment.format("DD.MM.YYYY");
                console.log(valMoment);
                $input.attr("type", "text");
                $input.val(val).trigger("change");
                if (isMobile) {
                    $input.datepicker("destroy");
                } else {
                    $input.datepicker(options);
                    if (isMoment)
                        $input.datepicker("update", valMoment.toDate());
                    if ($input.is(":focus")) $input.datepicker("show");
                }
            }
        });
    };

    $(window).on(
        "resize.bootstrapDatepickerMobile",
        $().bootstrapDatepickerMobile
    );
})(jQuery, Modernizr, window);

(function ($, Modernizr, window) {
    const options = {
        timeFormat: "H:i",
        scrollDefault: "now",
        minTime: "9",
        maxTime: "20",
    };

    /*globals moment*/
    $.fn.timepickerMobile = (ev) => {
        var $inputs = $("input.time-picker");
        var isMobile = $(window).width() <= 480 || Modernizr.touch;

        $inputs.each(function () {
            var $input = $(this);
            var val = $input.val();

            if (isMobile && Modernizr.inputtypes.time) {
                $input.timepicker("remove");
                $input.attr("type", "time");
                $input.val(val);
                $input.trigger("change");
            } else {
                $input.attr("type", "text");
                $input.val(val);
                $input.trigger("change");
                $input.timepicker(options);
            }
        });
    };

    $(window).on("resize", $().timepickerMobile);
})(jQuery, Modernizr, window);
