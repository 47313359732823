import { Route, Product, Ride, RouteJSONInterface, ProductJSONInterface, RideJSONInterface, CarType, CarTypeJSONInterface } from "@prague-old-car/api-client"
import { Moment } from "moment"

export type AppState = {
    routes: Route[]
    products: Product[]
    carTypes: CarType[]
    order?: Ride
}

export type AppStateJSON = {
    routes: RouteJSONInterface[]
    products: ProductJSONInterface[]
    carTypes: CarTypeJSONInterface[]
    order?: RideJSONInterface
}

export type CacheState = {
    routes: null | Moment,
    products: null | Moment,
    carTypes: null | Moment
}

export const appStateToJSON = (state: AppState): AppStateJSON => ({
    products: state.products?.map(product => product.toJSON()),
    routes: state.routes?.map(route => route.toJSON()),
    carTypes: state.carTypes?.map(carType => carType.toJSON()),
    order: state.order?.toJSON()
})

export const appStateFromJSON = (jsonState: AppStateJSON): AppState => ({
    products: jsonState.products?.map(product => Product.fromJSON(product)),
    routes: jsonState.routes?.map(route => Route.fromJSON(route)),
    carTypes: jsonState.carTypes?.map(carType => CarType.fromJSON(carType)),
    order: jsonState.order ? Ride.fromJSON(jsonState.order) : undefined
})

