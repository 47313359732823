import React, { Component } from 'react';
import GoogleMaps from '../Libs/GoogleMaps';
import GenericFormField, { FormFieldProps } from "./FormField";

/**
 * FormAutocompleteField 
 * 
 * @augments {React.Component<import('react-final-form').FieldRenderProps>}
*/
class FromAutocompleteField extends Component<FormFieldProps<any>> {
    autocompleteInput!: React.RefObject<HTMLInputElement>
    autocomplete?: google.maps.places.Autocomplete

    constructor(props: FormFieldProps<any>) {
        super(props);
        this.autocompleteInput = React.createRef();
        this.autocomplete = undefined;
    }

    async componentDidMount() {
        const maps = await GoogleMaps.getInstance()
        this.autocomplete = new maps.places.Autocomplete(
            this.autocompleteInput.current as HTMLInputElement,
            {
                bounds: new maps.LatLngBounds({ lat: 49.985773, lng: 14.228497 }, { lat: 50.135787, lng: 14.610385 }),
                componentRestrictions: { country: ['cz'] }
            }
        )

        this.autocomplete.addListener("place_changed", () => {
            if (this.autocompleteInput.current)
                this.props.input.onChange(this.autocompleteInput.current.value)
        });

        // Make sure google doesn't override our autocomplete settings
        if (this.autocompleteInput.current)
            this.autocompleteInput.current.addEventListener("focus", (e) => {
                const target = e.target as HTMLInputElement
                target.autocomplete = "new-password";
            });
    }

    render() {
        return (
            <GenericFormField
                inputRef={this.autocompleteInput}
                {...this.props}
            />
        );
    }
}

export default FromAutocompleteField;

