import React from 'react';

import OrderTable from './OrderTable';
import API, { PaymentStatus, PaymentType, Ride, RideSource, RideState, Route } from '@prague-old-car/api-client';
import CarMap from './CarMap';
import { useCars, useOrder } from '@prague-old-car/api-client-hooks';

/**
 * Shows email-like info of the order
 */
const OrderInfo: React.FC<{ order: Ride, routes: Route[] }> = ({ order, routes }) => {
    const stateTexts = {
        unconfirmed: (
            <div>
                <h2 className="font-weight-normal h3 pt-2 pb-2 font-secondary text-danger">Your reservation is being processed!</h2>
                <p>Thank you for choosing our company.<br />
                    We've recieved your reservation successfully. It is now being processed and <strong>we will confirm it within one hour.</strong></p>
            </div>),
        unconfirmedWaitingFromPayment: (link: string) => (<div>
            <h2 className="font-weight-normal h3 pt-2 pb-2 font-secondary text-warning">Your reservation is being processed, we are waiting for your payment!</h2>
            <p>Thank you for choosing our company.<br />
                We've recieved your reservation successfully. It is now being processed and <strong>we will confirm it within one hour.</strong></p>
            <p>
                <a className="btn btn-primary" href={link}>Make a payment</a>
            </p>
            <p>It can take a few moments before the payment gets processed. If you think there is something wrong, please contact us via the link below.</p>
        </div>),
        confirmed: (
            <div>
                <h2 className="font-weight-normal h3 pt-2 pb-2 font-secondary text-success">Your reservation has been confirmed!</h2>
                <p> Thank you for choosing our company. <br /> Your reservation has been confirmed. We hope you will enjoy your stay in Prague and we wish you a pleasant journey with our company!</p>
            </div>),
        deleted: (
            <div>
                <h2 className="font-weight-normal h3 pt-2 pb-2 font-secondary text-danger">This reservation has been deleted!</h2>
                <p>This reservation has been deleted and it is no longer valid.</p>
            </div>),
        alreadyConfirmed: (
            <div>
                <h2 className="font-weight-normal h3 pt-2 pb-2 font-secondary text-success">Reservation has been confirmed!</h2>
                <p>This reservation has already been confirmed. You can find it in our web application.</p>
            </div>),
        waitingFromPayment: (link: string) => (<div>
            <h2 className="font-weight-normal h3 pt-2 pb-2 font-secondary text-warning">Your reservation was confirmed, we are waiting for your payment!</h2>
            <p> Thank you for choosing our company. <br /> Your reservation has been confirmed. We are waiting for your payment.</p>
            <p>
                <a className="btn btn-primary" href={link}>Make a payment</a>
            </p>
            <p>It can take a few moments before the payment gets processed. If you think there is something wrong, please contact us via the link below.</p>
        </div>),
        paymentSuccesful: (
            <div>
                <h2 className="font-weight-normal h3 pt-2 pb-2 font-secondary text-success">Your reservation has been successful!</h2>
                <p> Thank you for choosing our company. <br /> Your reservation has been confirmed and paid. We hope you will enjoy your stay in Prague and we wish you a pleasant journey with our company!</p>
            </div>),
        adminWaitingForPayment: (
            <div>
                <h2 className="font-weight-normal h3 pt-2 pb-2 font-secondary text-warning">Reservation was confirmed, we are waiting for payment</h2>
                <p>This reservation has been confirmed. We are waiting for a payment.</p>
            </div>
        ),
        adminPaymentSuccesful: (
            <div>
                <h2 className="font-weight-normal h3 pt-2 pb-2 font-secondary text-success">Reservation was confirmed and paid</h2>
                <p>This reservation has been confirmed. Payment was successful.</p>
            </div>
        )
    }

    const isConfirmed = order.isOrderConfirmed()

    // TODO: Deleted
    const isDeleted = order.isDeleted()
    const isPaid = order.paymentStatus === PaymentStatus.Paid
    const isAuthorized = order.paymentStatus === PaymentStatus.Authorized
    const isAdmin = false

    let stateText = null;
    if (order.paymentType === PaymentType.CardInAdvance) {
        stateText = isDeleted ? stateTexts.deleted : (
            // TODO: Payment state and URL
            (isPaid || isAuthorized) ? stateTexts.paymentSuccesful : stateTexts.waitingFromPayment(order.paymentURL)
        )
    } else {
        stateText = isDeleted ? stateTexts.deleted :
            (isConfirmed ? stateTexts.confirmed : stateTexts.unconfirmed)
    }

    const teamLeader = order.assignments.length == 1 ? order.assignments[0] : order.assignments.find(assignment => assignment.teamLeader)
    const showLocation = teamLeader?.meta?.lat && teamLeader?.meta?.lon
    const showDriver = teamLeader?.meta?.driverPhoto && teamLeader?.meta?.driverName

    return (
        <div>

            <div className="container bg-white p-0 mt-md-3">

                {showLocation ?
                    <section className="order-content px-5 py-5" role="main">
                        {showDriver ?
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', marginBottom: 24 }}>
                                <img src={teamLeader?.meta?.driverPhoto} width={160} height={160} style={{ objectFit: 'cover', borderRadius: '50%' }} />
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 32 }}>
                                    <div style={{ fontWeight: 600 }}>Your driver:</div>
                                    <div style={{ fontSize: '1.6em', fontWeight: 800 }}>{teamLeader?.meta?.driverName}</div>
                                </div>
                            </div> : null}
                        <CarMap ride={order} />
                    </section> : null}

                <section className="order-content px-5 py-5" role="main">
                    {stateText}
                </section>

                <section className="order-content px-5 py-5" role="main">
                    <h3 className="font-weight-normal h4 pt-2 pb-2 font-secondary">Reservation details</h3>
                    <OrderTable order={order} routes={routes} />
                </section>
                {!isAdmin &&
                    <section className="order-content px-5 py-5" role="main">
                        <h3 className="font-weight-normal h4 pt-2 pb-2 font-secondary">Is there something wrong with your order?</h3>
                        <p>
                            <strong>Phone: </strong>+420 702 000 044<br />
                            <strong>Email: </strong> <a href={"mailto:info@pragueoldcar.com?subject=" + encodeURIComponent("Reservation " + order.getID())}>info@pragueoldcar.com</a>
                        </p>
                        <a className="btn btn-primary" href={("mailto:info@pragueoldcar.com?subject=") + encodeURIComponent("Reservation " + order.getID())}>Contact us</a>
                    </section>
                }
                {!isAdmin &&
                    <section className="order-content px-5 py-5" role="main">
                        <img className="img-fluid" src={"../images/header.jpg"} />
                    </section>
                }
            </div>

        </div>
    );
}

export default OrderInfo