import { Currency, ExchangeRate } from "@marekl/prices";

export default abstract class Params {
    static CompanyPOC = 1;
    static Company3Vet = 2;
    static CompanyPOCLimo = 3;

    static RouteA = 57;
    static RouteB = 59;
    static RouteC = 60;
    static RouteTransfer = 64;

    static RouteLimoAirport = 72;
    static RouteLimoTransfer = 73;
    static RouteLimoParty = 74;
    static RouteLimoWedding = 75;

    static CatPOCPrague = 1;
    static CatLimo = 2;
    static Cat3VetPrague = 3;
    static CatPOCCastles = 4;
    static Cat3VetCastles = 5;

    static CarTypeVeteran = 7;

    static LimoRoutes = [67, 68, 69, 70, 71];
    static LimoCars = [72, 73, 74, 75];

    static VetRoses = 52;
    static VetProsecco = 53;

    static EURCZK = new ExchangeRate({
        base: Currency.EUR,
        quote: Currency.CZK,
        rate: 24 * Math.pow(10, ExchangeRate.PRECISION),
    });
}
