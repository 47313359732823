import React, { Component, useEffect } from 'react';
import OrderInfo from '../Components/OrderInfo';

import API, { Product, Ride, Route } from '@prague-old-car/api-client'
import { APIProvider, useOrder } from '@prague-old-car/api-client-hooks';

const OrderDetail: React.FC<{
    order: Ride,
    routes: Route[],
    products: Product[]
}> = ({ order: initOrder, routes, products }) => {
    useEffect(() => {
        $(window).resize()
        window.scrollTo({ top: 0 })
    }, [])

    //@ts-ignore
    const { data: order, ...orderQuery } = useOrder(
        initOrder.userToken,
        { refetchInterval: 10000, initialData: initOrder },
        { location: "true" }
    )

    return (
        <div>

            <div className="container py-4">
                <div className="row align-items-md-end flex-md-row flex-column px-5">
                    <img src={"../images/logo.png"} width={150} height={80} />

                    <div className="pt-4 h5 pt-md-0 pl-md-3">
                        <span>{"Prague old car s.r.o."}</span>
                        <h1 className="lh-1 mt-1 mb-0 h2 font-weight-normal order-text-primary">Reservation #{(order || initOrder).getID()}</h1>
                    </div>
                </div>
            </div>

            <OrderInfo order={order || initOrder} routes={routes} />

            <div className="container">
                <section className="text-center py-5">
                    <a className="social-link" href={"https://www.facebook.com/pragueoldcar/"}>
                        <img src="../images/facebook.png" width="37" height="37" className="mx-2" alt="Facebook" style={{ border: 0 }} />
                    </a>
                    <a className="social-link" href={"https://www.instagram.com/prague_old_car/"}>
                        <img src="../images/instagram.png" width="37" height="37" className="mx-2" alt="Instagram" style={{ border: 0 }} />
                    </a>
                    <p className="pt-5">
                        © Prague old car s.r.o.
                        <br />
                        <br />
                        <i>Prague old car s.r.o., Rybná 716/24, Staré Město, 110 00 Praha 1</i>
                        <br />
                        <i>IČO: 01503251, 207563 C, Městský soud v Praze</i>
                    </p>
                </section>

            </div>

            <div className={(orderQuery.isLoading) ? "loading-overlay active" : "loading-overlay"}></div>
        </div>
    );
}


export default OrderDetail