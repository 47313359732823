import "regenerator-runtime";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import "./script.js";
import { appStateFromJSON } from "./AppState";

import moment from "moment-timezone";

moment.tz.setDefault("Europe/Prague");

// @ts-ignore
let preloadedState = window.__PRELOADED_STATE__;
const state = appStateFromJSON(preloadedState);
// @ts-ignore
delete window.__PRELOADED_STATE__;

ReactDOM.hydrate(<App state={state} />, document.getElementById("root"));
