import React, { createRef, useEffect } from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import IntlTelInput from 'react-intl-tel-input-v2';
import 'intl-tel-input/build/css/intlTelInput.css';

import './FormField.utils'
import './FormField.css'

export interface FormFieldProps<T> {
    input: FieldInputProps<T, HTMLElement>
    meta: FieldMetaState<T>
    title?: React.ReactNode
    className?: string
    children?: React.ReactNode
    inputRef?: React.RefObject<HTMLElement>
    inputClass?: string
    id?: string
    otherProps?: object
}

const FormField: React.FC<FormFieldProps<any>> = ({ input, meta, title, className, children, inputRef, inputClass, id, ...otherProps }) => {
    if (input.type === "date") inputClass = inputClass ? inputClass += " date-picker" : "date-picker"
    if (input.type === "time") inputClass = inputClass ? inputClass += " time-picker" : "time-picker"

    const onBlur = (e: React.FocusEvent<any>) => {
        input.onChange(input.value)
        input.onBlur(e)
    }

    // Hack to get onChange events from date-picker
    const ref = inputRef || createRef<HTMLElement>()
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
        if ((input.type === "date" || input.type === "time") && ref.current) {
            ref.current.onchange = (e) => {
                const target = e.target as undefined | any
                if (target?.type === "text") input.onChange(e)
            }
        }
    }, [ref.current])

    const inputId = id || `input-${input.name}`

    switch (input.type) {
        case 'checkbox':
            return (
                <div className={`custom-control custom-checkbox ${className || ''}`}>
                    <input
                        className={`custom-control-input ${meta.touched && meta.error ? 'is-invalid' : ''} ${inputClass || ''}`}
                        id={inputId}
                        ref={ref as React.RefObject<HTMLInputElement>}
                        {...otherProps}
                        {...input}
                    />
                    <label className="custom-control-label" htmlFor={inputId}>{title}</label>
                    <div className="invalid-feedback">
                        {meta.touched && meta.error}
                    </div>
                </div>
            )
        case 'radio':
            return (
                <div className={`custom-control custom-radio ${className || ''}`}>
                    <input
                        className={`custom-control-input ${meta.touched && meta.error ? 'is-invalid' : ''} ${inputClass || ''}`}
                        id={`${inputId}-${input.value}`}
                        ref={ref as React.RefObject<HTMLInputElement>}
                        {...otherProps}
                        {...input}
                    />
                    <label className="custom-control-label" htmlFor={`${inputId}-${input.value}`}>{title}</label>
                    <div className="invalid-feedback">
                        {meta.touched && meta.error}
                    </div>
                </div>
            )
        case 'textarea':
            return (
                <div className={`form-group ${className || ''}`}>
                    <label htmlFor={inputId}>{title}</label>
                    <textarea
                        className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''} ${inputClass || ''}`}
                        id={inputId}
                        ref={ref as React.RefObject<HTMLTextAreaElement>}
                        {...otherProps}
                        {...input}
                        onBlur={onBlur}
                    />

                    <div className="invalid-feedback">
                        {meta.touched && meta.error}
                    </div>
                    {children}
                </div>
            )
        case 'tel':
            // TODO: Focus
            return (
                <div className={`form-group ${className || ''}`}>
                    <label htmlFor={inputId}>{title}</label>
                    <IntlTelInput
                        className="w-100"
                        inputProps={{
                            className: (meta.touched && meta.error) ? "form-control is-invalid" : "form-control",
                            onBlur: onBlur
                        }}
                        intlTelOpts={{
                            separateDialCode: true,
                            preferredCountries: ['cz', 'us', 'de']
                        }}
                        {...input}
                        value={{ dialCode: input.value.dialCode, iso2: input.value.country, phone: input.value.number }}
                        onChange={(value: any) => {
                            return input.onChange({ dialCode: value.dialCode, country: value.iso2, number: value.phone })
                        }}
                        id={inputId}
                    />
                    <div className="invalid-feedback" style={(meta.touched && meta.error) ? { display: "block" } : {}}>
                        {meta.touched && meta.error}
                    </div>
                    {children}
                </div>
            )
        default:
            return (
                <div className={`form-group ${className || ''}`}>
                    <label htmlFor={inputId}>{title}</label>
                    <input
                        className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''} ${inputClass || ''}`}
                        id={inputId}
                        ref={ref as React.RefObject<HTMLInputElement>}
                        {...otherProps}
                        {...input}
                        onBlur={onBlur}
                    />
                    <div className="invalid-feedback">
                        {meta.touched && meta.error}
                    </div>
                    {children}
                </div>
            )
    }
}

export default FormField;
