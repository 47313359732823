import { Loader } from "@googlemaps/js-api-loader"

class GoogleMaps {
    private static loader?: Loader
    private static loaded: boolean = false;

    private constructor() { }

    public static async getInstance(): Promise<typeof google.maps> {
        if (!GoogleMaps.loaded && !GoogleMaps.loader) {
            GoogleMaps.loader = new Loader({
                apiKey: "AIzaSyDhiUBuA3Pyrpp0vG0iFq4jVTs--vRYHr8",
                libraries: ['places']
            })

            await GoogleMaps.loader.load()
                .then(() => {
                    GoogleMaps.loaded = true
                })
                .catch(() => {
                    GoogleMaps.loaded = false
                    GoogleMaps.loader = undefined
                })
        }
        return Promise.resolve(google.maps)
    }
}

export default GoogleMaps
