import React, { Component, ElementType, ReactElement } from 'react';

interface ModalProps {
    onClose?: (event: JQuery.Event) => void
    name: string
    title: string
    content: ReactElement | string
}

class Modal extends Component<ModalProps> {
    private modal: HTMLDivElement | null

    constructor(props: ModalProps) {
        super(props);
        this.modal = null
    }

    componentDidMount() {
        if (!this.modal) return

        $(this.modal).modal('show');

        if (this.props.onClose)
            $(this.modal).on('hidden.bs.modal', (e: JQuery.Event) => {
                if (this.props.onClose)
                    this.props.onClose(e)
            })
    }

    render() {
        const id = `modal-${this.props.name}`
        return (
            <div>
                <div className="modal fade" ref={modal => this.modal = modal} id={id} tabIndex={-1} role="dialog" aria-labelledby={id + "-title"} aria-hidden="true" >
                    <div className="modal-dialog" role="document" >
                        <div className="modal-content" >
                            <div className="modal-header" >
                                <h5 className="modal-title" id={id + "-title"}>{this.props.title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" >
                                {this.props.content}
                            </div>
                            <div className="modal-footer" >
                                <button type="button" className="btn btn-secondary" data-dismiss="modal"> Close </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal