import { PaymentType, Ride, Route } from '@prague-old-car/api-client'
import React from 'react';

/** 
 * Table of the order
 */
const OrderTable: React.FC<{ order: Ride, routes: Route[] }> = ({ order, routes }) => {

    const formatCars = (cars: number | null) => {
        if (!cars) cars = 1
        return `${cars} ${(cars > 1) ? "cars" : "car"}`
    }

    const formatPersons = (persons: number | null) => {
        if (!persons) persons = 1
        return `${persons} ${(persons > 1) ? "people" : "person"}`
    }

    const route = routes.find(route => route.getID() === order.routeId)

    return (
        <table className="reservation-details">
            <tbody>
                <tr>
                    <td><strong>{"TOUR"}</strong></td>
                    <td>{route?.getName().toLocaleUpperCase() || "UNKNOWN"} ({order.getDuration().asMinutes()} min)</td>
                </tr>
                <tr>
                    <td><strong>DATE</strong></td>
                    <td>{order.getStartTime()?.format("DD.MM.YYYY")}</td>
                </tr>
                <tr>
                    <td><strong>TIME</strong></td>
                    <td>{order.getStartTime()?.format("HH:mm")} - {order.getEndTime()?.format("HH:mm")}</td>
                </tr>
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td><strong>PICKUP</strong></td>
                    <td>{order.pickUp}</td>
                </tr>
                <tr>
                    <td><strong>DROP OFF</strong></td>
                    <td>{order.dropOff}</td>
                </tr>
                {order.flight ? <tr>
                    <td><strong>FLIGHT</strong></td>
                    <td>{order.flight}</td>
                </tr> : null}
                <tr>
                    <td><strong>GROUP</strong></td>
                    <td>{formatPersons(order.persons)} {order.carCount > 1 ? (formatCars(order.carCount)) : null}</td>
                </tr>
                {order.products.entries.map((entry, i) => <tr key={`product-${i}`}>
                    <td>{(i == 0) ? <strong>ADDITIONAL SERVICE</strong> : null}</td>
                    <td>{entry.quantity.format()}x {entry.name} ({entry.unitPrice.quantity(entry.quantity).getInclVAT().format()})</td>
                </tr>)}
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td><strong>NAME</strong></td>
                    <td>{order.name}</td>
                </tr>
                <tr>
                    <td><strong>EMAIL</strong></td>
                    <td>{order.email}</td>
                </tr>
                <tr>
                    <td><strong>PHONE</strong></td>
                    <td>{order.phone.format()}</td>
                </tr>
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                {order.note ? <tr>
                    <td><strong>NOTE</strong></td>
                    <td>{order.note}</td>
                </tr> : null}
                <tr>
                    <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                    <td><strong>TOTAL PRICE</strong></td>
                    <td>{order.getPriceOfRide().getInclVAT().add(order.getPriceOfProducts().getTotalInclVAT()).format()}</td>
                </tr>
                <tr>
                    <td><strong>PAYMENT</strong></td>
                    <td>{order.paymentType == PaymentType.CardInAdvance ? "Online payment" : "In cash"}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default OrderTable;